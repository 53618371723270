import React from "react";
import EmailForm from "../EmailForm";
import AppDetails from "../AppDetails";
import * as Icon from 'react-feather';

const LandingPage: React.FC = () => {
  return (
    <React.Fragment>
      <header className="App-header">
        <div className="App-title">Wither</div>
      </header>
      <AppDetails />
      <div className="Alice-quote">
        <p className="Alice">"And can all flowers talk?"</p>
        <p className="Flower">"As well as you can, and a great deal louder."</p>
        <p className="Attribution">- Through the Looking Glass</p>
      </div>
      <footer>
        <div className="App-email-form-box Wither-box">
          <EmailForm />
          <div className="footer-links">
            <div className="link">
              © 2020 We'll See, LLC
            </div>

            <div className="link">
              <a href="mailto:hello@wither.app">
                contact
              </a>
            </div>
            <div className="link">
              <a href="https://www.instagram.com/witherapp">
                  <Icon.Instagram />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default LandingPage;
