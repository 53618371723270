import whatIsWither from "./assets/what_is_wither.png";
import whyWither from "./assets/why_wither.png";
import friendsWither from "./assets/friends_wither.png"
import AppstoreBadges from "./AppstoreBadges";

import React from "react";

interface DetailsCopy {
  headerText: string;
  info: string | React.ReactElement;
  img: string;
  isHeaderClassName?: string;
}

const detailsInfo: DetailsCopy[] = [
  {
    headerText:
      "Express exactly how you feel using the lost language of flowers",
    info: <AppstoreBadges displayBoth={true} />,
    img: whyWither,
    isHeaderClassName: "Header-info"
  },
  {
    headerText:
      "Wither is a friendship app that helps you stay in touch. Send secret messages to friends, lovers, & enemies.",
    info:
      "During the Victorian era, it was common to give bouquets of flowers to with hidden meanings. Each flower had a specific intent and, when combined together in a bouquet, expressed new feelings, ideas or messages.",
    img: whatIsWither
  },
  {
    headerText: "Sometimes it's hard to put feelings into words, Wither lets you to use flowers instead.",
    info:
    "You pick the flowers that best describe how you feel and Wither translates that to something expressible & concrete. Wither was designed to be unobtrusive so that you can focus on having meaningful (or lighthearted or silly -- whatever) relationships with people, not phones.",
    img: friendsWither
  }
];

const AppDetails: React.FC = props => {
  return (
    <div className="App-details">
      {""}
      {detailsInfo.map((val, idx) => {
        return (
            <div className={`App-detail ${val.isHeaderClassName}`} key={idx}>
              <div className="App-detail-inner">
                <div className="App-detail-info">
                  <div className="App-detail-title">{val.headerText}</div>
                  {val.info}
                </div>
                <div className="App-detail-image">
                  <img src={val.img} alt="cell phone screenshot"></img>
                </div>
              </div>
            </div>
        );
      })}
    </div>
  );
};

export default AppDetails;
