import { firestore } from 'firebase/app';
import { storage } from './db';


export enum FlowerHeight {
  tall,
  short
}

export const flowerHeights: FlowerHeight[] = [FlowerHeight.tall, FlowerHeight.short];

interface Flower {
  stemURL: string;
  flowerURL: string;
  height: FlowerHeight;
  name: string;
  id: string;
}

const Flower = {
  fromDocument: async (doc: firestore.DocumentSnapshot): Promise<Flower> => {

    const data = doc.data()!;
    const [stemURL, flowerURL] = await Promise.all([
      getURL(data["stem_url"]),
      getURL(data["flower_url"])
    ]);

    return {
      stemURL,
      flowerURL,
      height: FlowerHeight[data["height"]] as any as FlowerHeight,
      name: data["colloquial_name"],
      id: doc.id
    }
  }
}

async function getURL(storageURL: string): Promise<string> {
  if (!storageURL) {
    return "";
  }

  return storage.refFromURL(storageURL).getDownloadURL();
}

export default Flower;
