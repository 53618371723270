import React from "react";
import Invite from "./models/Invite";
import { firestore } from "firebase/app";
import AppDetails from "./AppDetails";
import AppstoreBadges, {getLink, getOS, getInviteHost} from "./AppstoreBadges";
import Flower from "./models/flower";
import BouquetArranger, { Bouquet } from "./BouquetArranger";

interface InviteDetailsProps {
  invite: Invite;
  inviteId: string;
  bouquet?: Flower[];
}

function expirationCount(invite: Invite): number {
  let nowMillis = firestore.Timestamp.now().toMillis();
  let diffMillis = invite.expiresAt.toMillis() - nowMillis;
  let diffSeconds = diffMillis / 1000;
  let diffMinutes = diffSeconds / 60;
  let diffHours = diffMinutes / 60;
  let diffDays = diffHours / 24;
  return Math.floor(diffDays);
}


interface ExpirationCountProps {
  invite: Invite;
}

const ExpirationCount: React.FC<ExpirationCountProps> = props => {
  return (
    <span className="Expiration-count">
      {expirationCount(props.invite)} days
    </span>
  );
};


const InviteBouquet = (props: { flowers: Flower[], invite: Invite, inviteId: string }) => {
  return <>
    <div className="Bouquet">
      <Bouquet arranger={new BouquetArranger(props.flowers)}/>
    </div>
    <div className="Info Wither-box">
      <p>
        <span className="username">{props.invite.userName}</span> is trying to
        send you a flower bouquet with a hidden meaning.{" "}
        <a href={getLink(getOS())}>Get the app</a> to decode the message, then{" "}
        <a href={`${getInviteHost()}/invite/${props.inviteId}`}>open this invitation in Wither</a>.
      </p>
      <br/>
      <p>
        (Hurry, the flowers will wither in{" "}
        <ExpirationCount invite={props.invite}/>)
      </p>
    </div>
  </>;
}

const InviteNoBouquet = (props: { invite: Invite, inviteId: string }) => {
  return (
    <div className="Info Wither-box">
      <p>
        <span className="username">{props.invite.userName}</span> wants to add you as a friend on Wither.{" "}
        <a href={getLink(getOS())}>Get the app</a>, then{" "}
        <a href={`${getInviteHost()}/invite/${props.inviteId}`}>open this invitation in Wither</a> to
        start sending bouquets with hidden meanings.
      </p>
      <br/>
      <p>
        (Hurry, the invite will wither in{" "}
        <ExpirationCount invite={props.invite}/>)
      </p>
    </div>
  );
}

const InviteDetails: React.FC<InviteDetailsProps> = props => {
  return (
    <div className="Invite-details">
      <header>
        <div className="Sender">
          An invitation from <span className="username">{props.invite.userName}</span>
        </div>
        <div className="Expiration">
          Withers in <ExpirationCount invite={props.invite} />
        </div>
      </header>

      {props.bouquet ?
        <InviteBouquet flowers={props.bouquet} invite={props.invite} inviteId={props.inviteId}/> :
        <InviteNoBouquet invite={props.invite} inviteId={props.inviteId} />
      }
      <AppstoreBadges />
      <div className="divider-line">____</div>
      <AppDetails />
    </div >
  );
};

export default InviteDetails;
