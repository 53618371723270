import React from "react";
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";
import * as Icon from "react-feather";
import ReactPixel from "react-facebook-pixel";

const mailchimpUrl =
  "https://gmail.us4.list-manage.com/subscribe/post?u=b18ccc048275a7eb66f4b74e3&amp;id=c5f9cdec2a";

type ConfirmationStatus = "success" | "error" | "sending" | null;

interface CustomFormProps {
  status: ConfirmationStatus;
  message: string | Error | null;
  onValidated: any;
}

const CustomForm: React.FC<CustomFormProps> = props => {
  let email: HTMLInputElement;

  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    props.onValidated({
      EMAIL: email.value
    });

  if (props.status === "success") {
    return (
      <div className="form success">
        Thanks for signign up! We'll let you know as soon as we're ready to
        start testing!
      </div>
    );
  } else {
    return (
      <div>
        <div className={`inputs ${props.status}`}>
          <input
            ref={node => (email = node!)}
            type="email"
            placeholder="enter email here"
          />
          <button onClick={submit}>
            <Icon.ChevronRight size="20" color="#434651" />
          </button>
        </div>
        <div className={`error-message ${props.status === "error"}`}>
          {props.message}
        </div>
      </div>
    );
  }
};

export const EmailForm: React.FC = () => {
  return (
    <div className="App-email-form-box-inner">
      <div className="App-email-form-cta">Sign up to test out new features!</div>
      <div className="App-email-form">
        <MailchimpSubscribe
          url={mailchimpUrl}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={(formData: EmailFormFields) => {
                ReactPixel.track("Lead", {});
                subscribe(formData);
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default EmailForm;
