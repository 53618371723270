import React from "react";
import googleBadge from "./assets/google_badge.png";
import iphoneBadge from "./assets/iphone_badge.png";

enum OperatingSystem {
  iOS,
  Android,
  Web
}

export function getOS(): OperatingSystem {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (iosPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystem.iOS;
  } else if (/Android/.test(userAgent)) {
    os = OperatingSystem.Android;
  } else {
    os = OperatingSystem.Web;
  }

  return os;
}

export function getLink(os: OperatingSystem): string {
  switch (os) {
    case OperatingSystem.Android:
      return "https://play.google.com/store/apps/details?id=com.witherapp.wither"
    case OperatingSystem.iOS:
      return "https://apps.apple.com/us/app/wither/id1479614796"
    case OperatingSystem.Web:
      //returning ios link for "web" because I'm lazy for now and not expecting a lot of web users
      return "https://apps.apple.com/us/app/wither/id1479614796"
  }
}

export function getInviteHost(): string {
  if (window.location.hostname === "invite.wither.app") {
    return "https://wither.app"
  } else {
    return "https://invite.wither.app"
  }
}

interface AppButtonProps {
  badge: string;
  oppositeOs?: string;
  osLink: string;
  oppositeOsLink?: string;
}

const AppstoreBadge: React.FC<AppButtonProps> = props => {
  return (
    <div className="Appstore-badge">
      <a href={props.osLink}>
        <img alt="app store badge" src={props.badge} />
      </a>
      {props.oppositeOs && (
        <a href={props.oppositeOsLink}>{props.oppositeOs} instead?</a>
      )}
    </div>
  );
};

interface AppstoreBadgesProps {
  displayBoth?: boolean;
}

export const AppstoreBadges: React.FC<AppstoreBadgesProps> = (props:AppstoreBadgesProps) => {
  if (props.displayBoth) {
    return (
      <div className="Appstore-badges">
        <AppstoreBadge badge={iphoneBadge} osLink={getLink(OperatingSystem.iOS)} />
        <AppstoreBadge badge={googleBadge} osLink={getLink(OperatingSystem.Android)} />
      </div>)
  } else {
    switch (getOS()) {
      case OperatingSystem.Android:
        return (
          <div className="Appstore-badges">
            <AppstoreBadge
              badge={googleBadge}
              oppositeOs="iPhone"
              osLink={getLink(OperatingSystem.Android)}
              oppositeOsLink={getLink(OperatingSystem.iOS)}
            />
          </div>
        );
      case OperatingSystem.iOS:
        return (
          <div className="Appstore-badges">
            <AppstoreBadge
              badge={iphoneBadge}
              oppositeOs="iOS"
              osLink={getLink(OperatingSystem.iOS)}
              oppositeOsLink={getLink(OperatingSystem.Android)}
            />
          </div>
        );
      default:
        return (
          <div className="Appstore-badges">
            <AppstoreBadge badge={iphoneBadge} osLink={getLink(OperatingSystem.iOS)} />
            <AppstoreBadge badge={googleBadge} osLink={getLink(OperatingSystem.Android)} />
          </div>
        );
    }
  }
};

export default AppstoreBadges;
