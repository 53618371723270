import Flower, { FlowerHeight, flowerHeights } from "./models/flower";
import React from "react";
import './Bouquet.css';

interface PositionedFlower {
  imageURL: string;
  rotation: number;
}

class BouquetArranger {
  flowers: Array<Flower>;
  constructor(flowers: Array<Flower>) {
    this.flowers = flowers;
  }

  angle(flowerIndex: number, flowerCount: number, spreadDegrees: number): number {
    let position = (1 - flowerCount) / 2.0 + flowerIndex;
    return position * spreadDegrees * Math.PI / 180;
  }

  positionedFlowers(): PositionedFlower[] {
    const rotatedFlowers = flowerHeights.flatMap((height) => {
      return this.flowersOfHeight(height);
    });

    return [
      ...rotatedFlowers.map(([flower, rotation]) => {
        return {
          imageURL: flower.stemURL,
          rotation
        };
      }),
      ...rotatedFlowers.map(([flower, rotation]) => {
        return {
          imageURL: flower.flowerURL || flower.stemURL,
          rotation
        };
      })
    ];
  }

  flowersOfHeight(height: FlowerHeight): [Flower, number][] {
    const flowers = this.flowers.filter((flower) => flower.height === height);

    const flowerCount = flowers.length;

    const spreadDegrees = (() => {
      switch (height) {
        case FlowerHeight.short:
          return 40.0;
        case FlowerHeight.tall:
          return 20.0;
      }
    })();

    return flowers.map((flower, index) =>
      [
        flower,
        this.angle(index, flowerCount, spreadDegrees)
      ]
    );
  }
}


export const Bouquet: React.FC<{ arranger: BouquetArranger }> = ({ arranger }) => {
  return (
    <div className="bouquet" role="img" aria-label="A picture of a lovely bouquet">
      {arranger.positionedFlowers().map((positioned, idx) => {
        return <img className="bouquet-flower"
          key={idx}
          alt=""
          style={{ transform: `rotate(${positioned.rotation})` }}
          src={positioned.imageURL} />
      })}
    </div>
  );
}

export default BouquetArranger;
