import * as firebase from "firebase/app";
// Required for side-effects
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

firebase.initializeApp({
  apiKey: "AIzaSyDAudBWzhkoeqzqfVu-prGpdfyNp7GB3O0",
  projectId: "witherapp",
  appId: "1:768389211578:web:6d38a40d3b08c5971fe983",
  measurementId: "G-5EDBNRKC95"
});
firebase.analytics();

export default firebase.firestore();
export const storage = firebase.storage();
