import React from "react";
import { RouteComponentProps } from "react-router-dom";
import db from "../models/db";
import Invite from "../models/Invite";
import InviteDetails from "../InviteDetails";
import Flower from '../models/flower';

interface InviteProps {
  inviteId: string;
}

interface InviteStateLoading {
  type: "LOADING";
}

interface InviteStateFound {
  type: "FOUND";
  invite: Invite;
  inviteId: string;
  bouquet?: Flower[];
}

interface InviteStateNotFound {
  type: "NOT_FOUND";
}

type InviteState = InviteStateLoading | InviteStateFound | InviteStateNotFound;

export class InvitePage extends React.Component<
  RouteComponentProps<InviteProps>,
  InviteState
  > {
  constructor(props: RouteComponentProps<InviteProps>) {
    super(props);

    this.state = {
      type: "LOADING"
    };
  }

  async componentDidMount() {
    let inviteId = this.props.match.params.inviteId
    let inviteDoc = await db
      .collection("invites")
      .doc(inviteId)
      .get();


    if (inviteDoc.exists) {
      const invite = inviteDoc.data()! as Invite;

      const flowerDocs = await db.collection("flowers").get()

      const flowers = await Promise.all(flowerDocs.docs.map(Flower.fromDocument));

      const bouquet: Flower[] | undefined = (() => {
        let flowerIds = invite.bouquet;
        if (!flowerIds) {
          return undefined;
        } else {
          const definitelyFlowerIds = flowerIds;
          flowers
            .filter((flower) => definitelyFlowerIds.includes(flower.id));
        }
      })();

      this.setState({
        type: "FOUND",
        invite,
        inviteId,
        bouquet
      });
    } else {
      this.setState({
        type: "NOT_FOUND"
      });
    }
  }

  render() {
    switch (this.state.type) {
      case "FOUND":
        return <InviteDetails
          invite={this.state.invite}
          inviteId={this.state.inviteId}
          bouquet={this.state.bouquet} />;
      case "LOADING":
        return <div>Loading</div>;
      case "NOT_FOUND":
        return (
          <div>
            This bouquet was either claimed by someone else or expired. Ask the
            sender to send again!
          </div>
        );
    }
  }
}

export default InvitePage;
